import React, { useCallback, useEffect, useRef, useState } from "react";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Card, Radio, Typography, Col, Row } from "antd";
import { faCreditCard, faHandshake } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import IStep from "./IStep";
import ISalesOrder from "./ISalesOrder";
import { PaymentState, PaymentMethod } from "../../Utils/PaymentUtils";
import IOrderPlatforms from "./IOrderPlatforms";
import {
  findCustomerPlatform,
  findValueCustomer,
  findValuePlatform,
} from "../../Utils/ParameterServiceFunctional";
import { numberFormat } from "../../Utils/FlybyUtilsFunctional";
import IPaymentPayplug from "./IPaymentPayplug";
import ICustomerPlatform from "../document/ICustomerPlatform";

type Step5Props = {
  stepConf: IStep | undefined;
  values: any;
  onNextStep: any;
  updateSalesOrder: (saleOrder: ISalesOrder) => void;
  waiting: boolean;
};

const Step5 = (props: Step5Props): React.ReactElement => {
  const { stepConf, values, onNextStep, updateSalesOrder, waiting } = props;

  const valuesRef = useRef(values);

  const [selectPaymentMethod, setSelectPaymentMethod] = useState<
    string | undefined
  >(undefined);
  const [disabledState, setDisabledState] = useState<boolean>(false);

  const [paymentOrderCustomerState, setPaymentOrderCustomerState] =
    useState<string>();

  const [paymentOrderPlatformState, setPaymentOrderPlatformState] =
    useState<boolean>(false);

  const [multiPlatformState, setMultiPlatformState] = useState<Boolean>(false);

  const [checkedRadioState, setCheckedRadioState] = useState<string>();

  const { Text } = Typography;

  const PAYMENT_ORDER = sessionStorage.getItem("paymentOrder");
  const accessToken = sessionStorage.getItem("accessToken");
  const customerId = sessionStorage.getItem("customerId");

  const initIframe = useCallback(async (): Promise<void> => {
    let listWishList: number[] = [];
    setDisabledState(true);
    valuesRef.current.salesOrder.orderPlatforms.forEach(
      (orderPlatforms: IOrderPlatforms) => {
        if (orderPlatforms.wishlist !== undefined) {
          listWishList.push(orderPlatforms.wishlist.id);
        }
      }
    );

    const headers = new Headers();
    let deliveryId = valuesRef.current.salesOrder.deliveryId;
    let billingId = valuesRef.current.salesOrder.billingId;
    let step = stepConf?.key;
    let redirectUrl = `${window.location.origin}/shopping-cart?step=${step}&deliveryId=${deliveryId}&billingId=${billingId}`;

    headers.set("Authorization", "Bearer " + accessToken);
    headers.set("Accept", "application/json");
    headers.set("Content-Type", "application/json;charset=UTF-8");

    try {
      const response: IPaymentPayplug = await fetch(
        `/api/market/payPlug/ui?baseUri=/api&wishlists=${listWishList}&customer=${customerId}&redirectUrl=${encodeURIComponent(
          redirectUrl
        )}`,
        {
          headers,
        }
      )
        .then((data: any) => {
          return data.json();
        })
        .catch((err: any) => {
          console.log("error : ", err);
        });
      window.location.assign(response.payPlugUrl);
    } catch (err) {
      setDisabledState(false);
      return;
    }
    setCheckedRadioState(undefined);
    setSelectPaymentMethod(undefined);
  }, [accessToken, customerId, stepConf?.key]);

  //
  const onUpdateSalesOrder = useCallback(
    (paymentMethod: PaymentMethod) => {
      let salesOrder: ISalesOrder;
      if (paymentMethod === PaymentMethod.BUSINESS_ACCOUNT) {
        salesOrder = {
          ...valuesRef.current.salesOrder,
          paymentState: PaymentState.ACCEPTED,
          paymentMethod: paymentMethod,
        };
        // Fonction dans le provider
        updateSalesOrder(salesOrder);
      }
      if (paymentMethod === PaymentMethod.CREDIT_CARD) {
        salesOrder = {
          ...valuesRef.current.salesOrder,
          paymentState: PaymentState.PENDING,
          paymentMethod: paymentMethod,
        };
        // Fonction dans le provider
        updateSalesOrder(salesOrder);
      }
      setSelectPaymentMethod(paymentMethod);
      setCheckedRadioState(paymentMethod);
    },
    [updateSalesOrder]
  );

  // Fonction qui sert à cocher un mode de payment si il est unique
  const CheckIfUniquePaymentMethod = useCallback((): void => {
    if (
      multiPlatformState ||
      paymentOrderCustomerState?.toUpperCase() === "OPTIONAL"
    ) {
      // setCheckedRadioState(undefined);
    } else {
      if (
        paymentOrderCustomerState?.toUpperCase() === "DISABLED" ||
        PAYMENT_ORDER?.toLowerCase() === "false" ||
        PAYMENT_ORDER === null
      ) {
        onUpdateSalesOrder(PaymentMethod.BUSINESS_ACCOUNT);
        setCheckedRadioState(PaymentMethod.BUSINESS_ACCOUNT);
      }
      if (paymentOrderCustomerState?.toUpperCase() === "MANDATORY") {
        setCheckedRadioState(PaymentMethod.CREDIT_CARD);
        onUpdateSalesOrder(PaymentMethod.CREDIT_CARD);
      }
    }
  }, [
    PAYMENT_ORDER,
    multiPlatformState,
    onUpdateSalesOrder,
    paymentOrderCustomerState,
  ]);

  const checkPlatformAndCustomerPaymentOrder = useCallback(
    async (customerPlatforms: ICustomerPlatform[]) => {
      // Récupération du paramétrage de payment en ligne de la platform
      // si il y a qu"une seule platform
      if (customerPlatforms[0]) {
        const platformId = customerPlatforms[0].platform.id;
        try {
          const PLATFORM_PAYMENT_ORDER = await findValuePlatform(
            platformId,
            "PLATFORM_PAYMENT_ORDER"
          );
          if (PLATFORM_PAYMENT_ORDER !== undefined) {
            if (PLATFORM_PAYMENT_ORDER.toLowerCase() === "true") {
              setPaymentOrderPlatformState(true);
            }
          } else {
            setPaymentOrderPlatformState(false);
          }
        } catch {
          return;
        }
      }
      // Récupération du paramétrage du payment en ligne du customer
      try {
        const CUSTOMER_PAYMENT_ORDER = await findValueCustomer(
          "CUSTOMER_PAYMENT_ORDER"
        );
        if (
          CUSTOMER_PAYMENT_ORDER !== undefined &&
          PAYMENT_ORDER?.toLowerCase() === "true"
        ) {
          setPaymentOrderCustomerState(CUSTOMER_PAYMENT_ORDER);
        } else {
          setPaymentOrderCustomerState("DISABLED");
        }
      } catch {
        return;
      }
    },
    [PAYMENT_ORDER]
  );

  // Récupération des platformes du client
  // Puis récupérer le paramètre de paiement en ligne de la platform
  const checkMultiplatform = useCallback(async () => {
    try {
      const customerPlatforms: ICustomerPlatform[] =
        await findCustomerPlatform();
      let customerPlatformNumber = 0;
      customerPlatforms.forEach((customerPlatform: ICustomerPlatform) => {
        if (customerPlatform.state.toUpperCase() === "ENEBLED") {
          customerPlatformNumber++;
        }
      });
      // Plus de 1 platforme alors le client est multi-platforme
      if (customerPlatformNumber > 1) {
        setMultiPlatformState(true);
        // Sinon le client est uniplatforme donc le paiement en ligne
        // est potentiellement possible.
        // Vérification du paramétrage de paiement en ligne de la platform
      } else {
        setMultiPlatformState(false);
        checkPlatformAndCustomerPaymentOrder(customerPlatforms);
      }
    } catch {
      return;
    }
  }, [checkPlatformAndCustomerPaymentOrder]);

  useEffect(() => {
    checkMultiplatform();

    // if (selectPaymentMethod === PaymentMethod.CREDIT_CARD) {
    //   initIframe();
    // }

    CheckIfUniquePaymentMethod();
  }, [
    CheckIfUniquePaymentMethod,
    checkMultiplatform,
    initIframe,
    selectPaymentMethod,
  ]);

  return (
    <Col span={24}>
      {!paymentOrderCustomerState && (
        <div style={{ textAlign: "center", marginTop: 100, marginBottom: 100 }}>
          Le service de paiement est momentanément indisponible.
          <br />
          Nous mettons tout en oeuvre pour résoudre cet incident dans les
          meilleurs délais. <br />
          Merci de votre compréhension.
        </div>
      )}

      {paymentOrderCustomerState && (
        <div>
          <Row
            style={{
              position: "sticky",
              top: -20,
              backgroundColor: "#fff",
              zIndex: 1,
            }}
          >
            <Col span={24}>
              <div
                style={{
                  float: "right",
                  margin: 10,
                }}
              >
                <Button
                  type="primary"
                  size="large"
                  onClick={() => onNextStep(stepConf?.prevAction.prevStep)}
                  hidden={!stepConf?.prevAction.enable}
                  style={{ marginRight: 5 }}
                >
                  <LeftOutlined />
                  {stepConf?.prevAction.text}
                </Button>
                <Button
                  type="primary"
                  size="large"
                  onClick={() => onNextStep(stepConf?.nextAction.nextStep)}
                  hidden={!stepConf?.nextAction.enable}
                  disabled={
                    valuesRef.current.wishlistTotal.totalQuantitySelected <=
                      0 ||
                    selectPaymentMethod === undefined ||
                    selectPaymentMethod === PaymentMethod.CREDIT_CARD
                  }
                >
                  {stepConf?.nextAction.text}
                  <RightOutlined />
                </Button>
              </div>
            </Col>
          </Row>

          <Col span={14} offset={5}>
            <Radio.Group
              style={{ width: "100%" }}
              value={checkedRadioState}
              onChange={(e) => {
                onUpdateSalesOrder(e.target.value);
              }}
            >
              {(paymentOrderCustomerState.toUpperCase() === "DISABLED" ||
                paymentOrderCustomerState.toUpperCase() === "OPTIONAL") && (
                <Card
                  type="inner"
                  bodyStyle={{ padding: 0 }}
                  onClick={() => {
                    onUpdateSalesOrder(PaymentMethod.BUSINESS_ACCOUNT);
                  }}
                  style={{ marginBottom: 15, cursor: "pointer" }}
                  title={
                    paymentOrderCustomerState.toUpperCase() === "OPTIONAL" ? (
                      <Radio
                        onClick={() => {
                          onUpdateSalesOrder(PaymentMethod.BUSINESS_ACCOUNT);
                        }}
                        value={PaymentMethod.BUSINESS_ACCOUNT}
                      >
                        <Text strong>
                          <FontAwesomeIcon icon={faHandshake} /> Mes conditions
                          de règlement habituelles
                        </Text>
                      </Radio>
                    ) : (
                      <Text strong>
                        <FontAwesomeIcon icon={faHandshake} /> Mes conditions de
                        règlement habituelles
                      </Text>
                    )
                  }
                >
                  {selectPaymentMethod === PaymentMethod.BUSINESS_ACCOUNT && (
                    <div style={{ padding: 16 }}>
                      <p>
                        Vous êtes un client en compte, vous avez choisi de payer
                        en utilisant vos conditions de règlement habituelles :
                      </p>
                      <ul>
                        <li>
                          Le montant total de votre commande s'élève à{" "}
                          <b>
                            {numberFormat(
                              valuesRef.current.wishlistTotal
                                .totalPaymentAmountInclTaxesSelected,
                              2
                            )}{" "}
                            € TTC
                          </b>
                        </li>
                        <li>
                          Veuillez confirmer votre commande en cliquant sur «{" "}
                          {stepConf?.nextAction.text} »
                        </li>
                      </ul>
                    </div>
                  )}
                </Card>
              )}
              {!multiPlatformState &&
                (paymentOrderCustomerState.toUpperCase() === "MANDATORY" ||
                  paymentOrderCustomerState.toUpperCase() === "OPTIONAL") &&
                paymentOrderPlatformState && (
                  <Card
                    type="inner"
                    bodyStyle={{ padding: 0 }}
                    onClick={() => {
                      onUpdateSalesOrder(PaymentMethod.CREDIT_CARD);
                    }}
                    style={{ marginBottom: 15 }}
                    title={
                      paymentOrderCustomerState.toUpperCase() === "OPTIONAL" ? (
                        <Radio
                          onClick={() => {
                            onUpdateSalesOrder(PaymentMethod.CREDIT_CARD);
                          }}
                          value={PaymentMethod.CREDIT_CARD}
                        >
                          <Text strong>
                            <FontAwesomeIcon icon={faCreditCard} /> Carte
                            bancaire
                          </Text>
                        </Radio>
                      ) : (
                        <Text strong>
                          <FontAwesomeIcon icon={faCreditCard} /> Carte bancaire
                        </Text>
                      )
                    }
                  >
                    {selectPaymentMethod === PaymentMethod.CREDIT_CARD && (
                      <div style={{ padding: 16 }}>
                        <p>
                          La transaction s'effectuera sur un serveur bancaire
                          sécurisé où les informations nécessaires vous seront
                          demandées.
                          <br />
                          A n'importe quel moment vous pourrez revenir au choix
                          des moyens de paiement sur notre boutique en cliquant
                          sur le bouton d'annulation depuis le serveur bancaire.
                          <br />
                          Cliquez sur le logo correspondant à votre carte pour
                          être redirigé vers le serveur bancaire adéquat.
                          <br />
                        </p>

                        <Button
                          disabled={disabledState || waiting}
                          type="primary"
                          size="large"
                          onClick={() => initIframe()}
                        >
                          {disabledState || waiting
                            ? "Veuillez patienter..."
                            : "Accéder au paiement"}
                        </Button>
                      </div>
                    )}
                  </Card>
                )}
            </Radio.Group>
          </Col>
        </div>
      )}
    </Col>
  );
};

export default Step5;
